<template>
  <div class="member" :style="`background-image: url(${staticUrl}member_page.png)`">
    <div class="member-opacity">

      <!-- top -->
      <div class="top e-pt-50 column items-center">
        <div class="row justify-center">
          <img class="height-100" :src="staticUrl+'vipLogo.png'" alt="" />
        </div>
        <div class="izbol text-color-white-1 font-18 e-pb-10 bottom-heng">{{ $t(`common.subscriptionMember`) }}</div>
      </div>

      <!-- 价格列表 -->
      <div class="e-mx-30 e-mt-50">
        <div class="price-box e-pa-20" v-for="item in priceList"
          :style="`background-image: url(${staticUrl}price-box.png)`" @click="onPrice(item.price)">
          <div class="row justify-between items-center">
            <div class="row">
              <img class="height-20" :src="staticUrl+'HD.png'" alt="" />
              <img class="height-20 e-mx-10" :src="staticUrl+'4K.png'" alt="" />
            </div>
            <div class="text-color-white-2 izbol">كۈنلىكى {{ item.onePrice }} موچەندىن توختايدۇ</div>
          </div>
          <div class="price-list row items-center justify-between e-mt-10"
            :style="`background-image: url(${staticUrl}price-list.png)`">
            <div class="e-pl-5">
              <span class="text-color-white-2 font-20">{{ item.price }}￥</span>
              <del class="text-color-grey-4">{{ item.origenPrice }}￥</del>
            </div>
            <div class="izbol rtl text-white font-20 e-pr-40">{{ $t(`common.${item.date}`) }}</div>
          </div>
        </div>
      </div>

      <!-- 底部 -->
      <div class="izbol text-color-grey-3 column justify-center items-center e-mt-30 e-pb-50">
        <div>BAYAN</div>
        <div>FILM AND TELEVISION</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'member',
  components: {},
  data() {
    return {
      staticUrl: 'https://bayan.adaxtv.com/static/image/',
      priceList: [
        { id: 1, price: '12', onePrice: '4', date: 'monthMembership_1', origenPrice: '20' },
        { id: 2, price: '30', onePrice: '3', date: 'monthMembership_3', origenPrice: '60' },
        { id: 3, price: '60', onePrice: '3', date: 'monthMembership_6', origenPrice: '79' },
        { id: 4, price: '99', onePrice: '3', date: 'monthMembership_12', origenPrice: '129' },
      ],
    }
  },
  created () {
    this.getLanguage();
  },
  methods: {
    // 获取语言设置
    getLanguage () {
      let lang = localStorage.getItem('language');
      if (lang == 1) {
        this.$i18n.locale = 'ug'
      } else {
        this.$i18n.locale = 'zh'
      }
    },
    // 点击价格
    onPrice(price) {
      console.log("价格: ", price);
    },
  },
}
</script>

<style scoped>
.member {
  background-size: cover;
  background-repeat: no-repeat;
}
.member-opacity { background-color: rgba(48, 56, 66, 0.7); }
.bottom-heng { border-bottom: solid 1px #d0d0d0; }
.price-box {
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
}
.price-list {
  height: 55px;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>