<template>
  <div class="OriginalFilm" :style="`background-image: url(${staticUrl}originalFilmBg.png)`">

    <div class="OriginalFilmOpacity full-height">
      <!-- titile -->
      <div class="izbol text-color-grey-6 e-pt-50 text-center font-20">{{ $t(`common.originalFilm`) }}</div>

      <!-- search -->
      <div class="row justify-center e-mt-20">
        <div class="height-40 row items-center e-bg-grey-3 border-radiusL-10 e-px-10"></div>
        <input type="text"
          class="e-bg-grey-3 border-radius-none height-40 rtl outline-none font-16 text-white izbol width-250"
          placeholder="IZIMEKQI BOLGAN ESIMNI KIRGUZUG" v-model="searchKey">
        <div class="height-40 row items-center e-bg-grey-3 border-radiusR-10 e-px-10" style="padding: 7px;"
          @click="onSearch">
          <div style="background-color: #9a9a9a; width: 1px;" class="full-height"></div>
          <img :src="staticUrl+'search-icon.png'" alt="" class="width-25 e-ml-5" />
        </div>
      </div>

      <!-- 年份 -->
      <div class="row e-px-30 e-mt-20">
        <div v-for="item in years" class="e-mr-10 e-mb-10 border-bottom-1 text-white" @click="onYear(item.year)">{{
          item.year }}</div>
      </div>

      <!-- 片 -->
      <div class="item-box e-px-30 e-mt-20">
        <div class="" v-for="(item, index) in movieList" @click="onMovie(item.id)">
          <img :class="[index === 0 ? 'e-mt-50' : index === 2 ? 'e-mt-50' : index > 1 && index % 3 == 1 ? 'relative-position top--50' : '']" class="full-width"
            :src="staticUrl+item.imageUrl" alt="" />
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'OriginalFilm',
  components: {},
  data () {
    return {
      searchKey: '',
      staticUrl: 'https://bayan.adaxtv.com/static/image/',
      years: [
        { id: 1, year: '2010' },
        { id: 2, year: '2012' },
        { id: 3, year: '2013' },
        { id: 4, year: '2014' },
        { id: 5, year: '2015' },
        { id: 6, year: '2016' },
        { id: 7, year: '2017' },
        { id: 8, year: '2018' },
        { id: 9, year: '2019' },
        { id: 10, year: '2020' },
        { id: 11, year: '2021' },
        { id: 12, year: '2022' },
        { id: 13, year: '2023' },
        { id: 14, year: '2024' },
        { id: 15, year: '2025' },
      ],
      movieList: [
        { id: 1, imageUrl: 'demo-1.png' },
        { id: 2, imageUrl: 'demo-2.png' },
        { id: 3, imageUrl: 'demo-3.png' },
        { id: 4, imageUrl: 'demo-4.png' },
        { id: 5, imageUrl: 'demo-5.png' },
        { id: 6, imageUrl: 'demo-6.png' },
        { id: 7, imageUrl: 'demo-1.png' },
        { id: 8, imageUrl: 'demo-2.png' },
        { id: 9, imageUrl: 'demo-3.png' },
        { id: 10, imageUrl: 'demo-4.png' },
        { id: 11, imageUrl: 'demo-5.png' },
        { id: 12, imageUrl: 'demo-6.png' },
        { id: 13, imageUrl: 'demo-1.png' },
        { id: 14, imageUrl: 'demo-2.png' },
        { id: 15, imageUrl: 'demo-3.png' },
      ],
    }
  },
  created () {
    this.getLanguage();
  },
  methods: {
    // 获取语言设置
    getLanguage () {
      let lang = localStorage.getItem('language');
      if (lang == 1) {
        this.$i18n.locale = 'ug'
      } else {
        this.$i18n.locale = 'zh'
      }
    },
    // 点击搜索
    onSearch () {
      console.log("searchKey: ", this.searchKey);
    },

    // 点击年份
    onYear (year) {
      console.log("year: ", year);
    },

    // 点击电影
    onMovie (id) {
      this.$router.push({ name: 'playPage' })
    }
  },
}
</script>

<style scoped>
.OriginalFilm {
  background-size: contain;
}
.OriginalFilmOpacity { background-color: rgba(48, 56, 66, 0.7); }
.item-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  padding-bottom: 50px;
}

</style>