<template>
  <div class="contact" :style="`background-image: url(${staticUrl}member_page.png)`">
    <div class="member-opacity">

      <!-- top -->
      <div class="row justify-between items-center e-pa-30">
        <div @click="onBack"><img class="height-20" :src="staticUrl+'back.png'" alt="" /></div>
        <div class="izbol text-white font-18">بىز بىلەن ئالاقىلشىڭ</div>
        <div></div>
      </div>

      <!-- 二维码 -->
      <div class="row justify-center e-mt-50">
        <img class="height-270" :src="staticUrl+'wechat-qrcode.png'" alt="" />
      </div>

      <!-- 按钮 -->
      <div class="e-mt-30 e-px-100" @click="dowlandQRCode">
        <div class="e-bg-light-green-1 izbol row justify-center e-py-10 border-radius-8">ئىككىلىك كودنى ساقلىۋېلىش</div>
      </div>

      <!-- 提示 -->
      <div class="izbol coulmun e-mt-50 text-color-grey-4 text-center font-16">
        <div>ئۈستىدىكى ئىككىلىك كودنى تىلفۇنىڭىزغا ساقلىۋالغاندىن كىيىن</div>
        <div>ئۈندىداردا سىكانىرلاپ ئالاقىلشىڭ</div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'contact',
  components: {},
  data() {
    return {
      staticUrl: 'https://bayan.adaxtv.com/static/image/',
      msg: 'this is Contact page.'
    }
  },
  methods: {
    // 返回
    onBack() {
      this.$router.go(-1)
    },

    // 下载二维码
    dowlandQRCode() {
      console.log("下载二维码");
    }
  },
}
</script>

<style scoped>
.contact {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}
.member-opacity {
  height: 100vh;
  background-color: rgba(48, 56, 66, 0.8);
}
</style>