<template>
  <div class="playPage height-fit">
    <div class="playPage_bg full-height">

      <!-- top -->
      <div class="row items-center justify-between e-px-30 e-pt-30">
        <div @click="onBack"> <img class="height-20" :src="staticUrl+'back_2.png'" alt="" /> </div>
        <div class="text-white column items-center">
          <div class="font-16">永远的爱情</div>
          <div class="font-8">2小时12分钟</div>
        </div>
        <div></div>
      </div>

      <!-- 播放器 -->
      <div class="height-250 bg-dark">
        <video class="height-max-250" :src="linSrc"></video>
      </div>

      <!-- 多功能 -->
      <div class="row justify-between e-px-30 e-mt-20">
        <div class="column items-center justify-center" v-for="(item, index) in backList" @click="onGongNeng(item.id)">
          <div class="e-bg-grey-5 row items-center justify-center width-50 height-50 border-radius-50p">
            <img class="height-30" :src="staticUrl + item.img" alt="" />
          </div>
          <div class="izbol text-white e-mt-10 font-12">{{ $t(`common.${item.name}`) }}</div>
        </div>

      </div>

      <!-- 分割 -->
      <div class="column items-center e-mt-30 e-px-30">
        <div class="izbol text-white">{{ $t(`common.relatedFilm`) }}</div>
        <div class="e-bg-grey-6 full-width e-mt-10" style="height: 1px;"></div>
      </div>

      <!-- 轮播图 -->
      <div class="e-px-30 e-mt-20 overflow">
        <swiper @slideChange="onSlideChange" :slidesPerView="3" :spaceBetween="30" :loop="true" :freeMode="true"
          :autoplay="{
            delay: 250000,
            disableOnInteraction: false,
          }" :modules="modules" class="mySwiper">
          <swiper-slide v-for="(item, index) in movieList" @click="onMovie(item.id)">
            <div :class="swiperIndex == index ? 'width-120 e-ml--10' : 'width-100'" class="border-radius-8">
              <img class="full-width" :src="staticUrl + item.imageUrl" alt="" />
            </div>
          </swiper-slide>
        </swiper>
      </div>

    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default {
  name: 'PlayPage',
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      staticUrl: 'https://bayan.adaxtv.com/static/image/',
      linSrc: 'https://www.bilibili.com/video/BV1oGmwYbEmB?t=12.7',
      backList: [
        { id: 1, name: 'home', img: 'home.png' },
        { id: 2, name: 'shareWithFriends', img: 'send.png' },
        { id: 3, name: 'collect', img: 'favorite.png' },
        { id: 4, name: 'complaintAndAdvice', img: 'suggest.png' },
      ],
      movieList: [
        { id: 1, imageUrl: 'demo-1.png', num: '8.3' },
        { id: 2, imageUrl: 'demo-2.png', num: '7.5' },
        { id: 3, imageUrl: 'demo-3.png', num: '7.9' },
        { id: 4, imageUrl: 'demo-4.png', num: '9.5' },
        { id: 5, imageUrl: 'demo-5.png', num: '6.7' },
        { id: 6, imageUrl: 'demo-6.png', num: '8.6' },
        { id: 7, imageUrl: 'demo-1.png', num: '8.1' },
        { id: 8, imageUrl: 'demo-2.png', num: '7.6' },
        { id: 9, imageUrl: 'demo-3.png', num: '7.7' },
        { id: 10, imageUrl: 'demo-4.png', num: '8.8' },
        { id: 11, imageUrl: 'demo-5.png', num: '5.9' },
        { id: 12, imageUrl: 'demo-6.png', num: '9.0' },
      ],
      swiperIndex: 0,
      modules: [Autoplay, Pagination, Navigation]
    }
  },
  mounted () {
    this.addClassesToSwiperWrapper();
  },
  created () {
    this.getLanguage();
  },
  methods: {
    // 获取语言设置
    getLanguage () {
      let lang = localStorage.getItem('language');
      if (lang == 1) {
        this.$i18n.locale = 'ug'
      } else {
        this.$i18n.locale = 'zh'
      }
    },
    // 返回
    onBack () {
      this.$router.go(-1);
    },

    // 点击功能
    onGongNeng(e) {
      
      switch (e) {
        case 1:
          this.$router.push({ name: 'home' });
          break;
        case 2:
          console.log("分享: ", e);
          break;
        case 3:
          console.log("收藏: ", e);
          break;
        case 4:
          this.$router.push({ name: 'suggest' });
          break;
        default:
          break;
      }
    },

    // 轮播图切换
    onSlideChange (swiper) {
      const activeIndex = swiper.realIndex;

      if (activeIndex == this.movieList.length - 1) {
        this.swiperIndex = 0;
      } else {
        this.swiperIndex = activeIndex + 1;
      }

      for (let item of this.movieList) {
        if (item.id == activeIndex + 2) {
          this.bgImageName = item.imageUrl;
        }
      }

    },

    // 点击电影
    onMovie (id) {
    this.$router.push({ name: 'playPage' })
    },

    // 找到 swiper-wrapper 元素
    addClassesToSwiperWrapper () {
      // 找到 swiper-wrapper 元素
      const swiperWrapper = document.querySelector('.swiper-wrapper');
      if (swiperWrapper) {
        // 设置内联样式
        swiperWrapper.style.display = 'flex';
        swiperWrapper.style.alignItems = 'center';
      }
    },
  }
}
</script>
<style scoped>
.playPage_bg { background-color: rgba(47, 53, 59, 0.6); }
</style>
