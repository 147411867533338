<template>
  <div class="mine e-bg-grey-1 height-fit">

    <!-- hader -->
    <div class="header" :style="`background-image: url(${iconUrl+'header.png'})`">
      <!-- top -->
      <div class="e-px-30 e-pt-30 row justify-between">
        <div @click="onBack"><img class="height-20" :src="iconUrl+'back.png'" alt="" /></div>
        <div>
          <!-- <img class="height-20 e-mx-20" :src="iconUrl+'transmit.png'" alt="" />
          <img class="height-20" :src="iconUrl+'setting.png'" alt="" /> -->
        </div>
      </div>

      <!-- 用户信息 -->
      <div class="e-pt-90 izbol">
        <!-- 头像、用户名、用户 ID -->
        <div class="column items-center text-white">
          <div><img class="width-100" src="../../assets/image/avatar.png" alt="" /></div>
          <div class="font-16">ESQER ABDURISHIT</div>
          <div class="font-16">ID: 00001</div>
        </div>

        <!-- 会员框 -->
        <div class="e-px-30" @click="onVIPPage">
          <div class="vipBox e-mt-30 row justify-around relative-position" :style="`background-image: url(${iconUrl+'vipdiv.png'})`">
            <div>
              <div class="absolute top--40"><img class="width-90" :src="iconUrl+'vipLogo.png'" alt="" /></div>
              <div class="e-mt-60 e-bg-orange-1 text-white e-px-10 e-py-5 border-radius-8">{{ $t(`common.purchase`) }}</div>
            </div>
            <div class="izbol text-white column e-mt-25">
              <div class="row justify-end font-20">SVIP</div>
              <div class="heng"></div>
              <div>{{ $t(`common.subscribeToUnlimitedViewing`) }}</div>
            </div>
          </div>
        </div>

      </div>

      <!-- 菜单 -->
      <div class="e-px-30 text-white izbol e-bg-grey-1">
        <div v-for="item in litsData"
          class="row items-center e-bg-grey-2 e-mb-20 border-radius-8 e-pa-all rtl boxShadow" @click="onList(item.id)">
          <img class="width-30" :src="item.iconUrl" alt="" />
          <span class="e-mx-20">{{ $t(`common.${item.name}`) }}</span>
        </div>

        <!-- 底部 -->
        <div class="text-color-grey-2 izbol e-bg-grey-1 column items-center e-py-50">
          <div>BAYAN</div>
          <div>FILM AND TELEVISION</div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
let imageUrl = 'https://bayan.adaxtv.com/static/image/'

export default {
  name: 'mine',
  components: {},
  data () { 
    return {
      mine: 'Mine',
      iconUrl: 'https://bayan.adaxtv.com/static/image/',
      litsData: [
        { id: 1, name: 'haveBought', iconUrl: `${imageUrl}shop.png` },
        { id: 2, name: 'preview', iconUrl: `${imageUrl}play.png` },
        { id: 3, name: 'share', iconUrl: `${imageUrl}share.png` },
        { id: 4, name: 'contactUs', iconUrl: `${imageUrl}content.png` },
        { id: 5, name: 'aboutUs', iconUrl: `${imageUrl}about.png` },
      ],
    } 
  },
  created () {
    this.getLanguage();
  },
  methods: {
    // 获取语言设置
    getLanguage () {
      let lang = localStorage.getItem('language');
      if (lang == 1) {
        this.$i18n.locale = 'ug'
      } else {
        this.$i18n.locale = 'zh'
      }
    },
    // 点击列表
    onList (id) {
      switch (id) {
        case 1:
          this.$router.push({ name: 'haveBought' });
          break;
        case 2:
          this.$router.push({ name: 'preview' });
          break;
        case 3:
          
          break;
        case 4:
          this.$router.push({ name: 'contact' });
          break;
        case 5:
          this.$router.push({ name: 'aboutus' });
          break;
        default:
          break;
      }
      console.log("id: ", id);
    },

    // 点击会员
    onVIPPage() {
      this.$router.push({ name: 'member' })
    },

    // 返回
    onBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.header {
  background-size: cover;
  background-repeat: no-repeat;
  height: 360px;
}
.vipBox {
  background-size: contain;
  background-repeat: no-repeat;
  height: 120px;
}
.heng {
  background-color: #e4fb31;
  height: 3px;
  margin-bottom: 10px;
}
.boxShadow {
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.3);
}
</style>
