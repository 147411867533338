<template>
  <router-view />
</template>

<script>

export default {

  components: {},

  setup () {
    return {}
  }
}
</script>

<style>
@import '../src/assets/style/style.css';
</style>
