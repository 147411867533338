<template>
  <div class="suggest height-fit" :style="`background-image: url(${staticUrl}preview_bg.png)`">

    <!-- bg_Image -->
    <div class="suggest_bg full-height e-px-30">

      <!-- top -->
      <div class="row items-center justify-between e-pt-30">
        <div @click="onBack"> <img class="height-20" :src="staticUrl + 'back_2.png'" alt="" /> </div>
        <div class="izbol text-white font-18">ئەرىز-شىكايەت</div>
        <div></div>
      </div>

      <!-- content -->
      <div class="column">
        <textarea v-model="contet" class="e-mt-30 izbol rtl e-pa-20 outline-none height-200"
          placeholder="پىكىر تەكلىپىڭىزنى قالدۇرۇپ قۇيۇڭ ..."></textarea>

        <input type="text" v-model="phone" class="e-mt-30 izbol rtl e-pa-10 outline-none"
          placeholder="ئالاقىلشىش نۇمۇرىڭىزنى قالدۇرۇپ قۇيۇڭ" />
      </div>

      <!-- send -->
      <div class="row justify-center e-mt-30">
        <q-btn class="izbol" style="background: #b3cd30; padding: 0 50px;" text-color="dark" label="يوللاش" @click="onSend" />
      </div>

    </div>

  </div>
</template>
<script>
export default {
  name: 'Suggest',
  data () {
    return {
      staticUrl: 'https://bayan.adaxtv.com/static/image/',
      phone: "",
      contet: "",
    }
  },
  methods: {
    // 返回
    onBack () {
      this.$router.go(-1)
    },

    // 发送
    onSend() {
      this.contet = '';
      this.phone = '';
      this.onBack();
    },
  }
}
</script>
<style scoped>
.suggest { background-size: cover; }
.suggest_bg { background-color: rgba(47, 53, 59, 0.8); }
</style>
