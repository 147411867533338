<template>
  <div class="documentaryFilm">

    <div class="documentaryFilmPage" :style="`background-image: url(${staticUrl}documentaryFilm.png)`">
      <div class="opacity-page">

        <!-- Title -->
        <div class="title text-white column items-center e-pt-50">
          <div class="izbol font-15" style="padding: 0 80px 0 0;">FILIM TURLER</div>
          <div class="izbol font-20">{{ $t(`common.documentaryFilm`) }}</div>
        </div>

        <!-- type -->
        <div class="row justify-between e-px-30 e-mt-50">
          <div v-for="item in typeList" @click="onType(item.id)"
            class="e-mb-20 width-150 height-80 relative-position e-bg-light-green-1 border-radius-8 izbol">
            <div class="text-white font-18 rtl line-height-80 e-mr-10">
              <img class="absolute full-width right-0" style="height: 78px;" :src="staticUrl+item.imageUrl" alt="">
              <div class="absolute">{{ $t(`common.${item.name}`) }}</div>
            </div>
          </div>
        </div>

        <!-- 底部 -->
        <div class="e-mt-10 relative-position row justify-center">
          <div class="text-color-grey-5 absolute font-60 izbol">BAYAN</div>
          <div class="text-white absolute font-10 line-height-70 izbol">FILM AND TELEVISION</div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'DocumentaryFilm',
  components: {},
  data () {
    return {
      staticUrl: 'https://bayan.adaxtv.com/static/image/',
      typeList: [
        { id: 1, name: 'people', imageUrl: 'xahis.png' },
        { id: 2, name: 'science', imageUrl: 'tehnika.png' },
        { id: 3, name: 'nature', imageUrl: 'tabiat.png' },
        { id: 4, name: 'history', imageUrl: 'tarih.png' },
        { id: 5, name: 'sports', imageUrl: 'tantarbiya.png' },
        { id: 6, name: 'culture', imageUrl: 'madiniyat.png' },
        { id: 7, name: 'specialty', imageUrl: 'kasip.png' },
        { id: 8, name: 'tour', imageUrl: 'sayahat.png' },
        { id: 9, name: 'interview', imageUrl: 'sohpat.png' },
        { id: 10, name: 'life', imageUrl: 'turmux.png' },
      ],
    }
  },
  created () {
    this.getLanguage();
  },
  methods: {
    // 获取语言设置
    getLanguage () {
      let lang = localStorage.getItem('language');
      if (lang == 1) {
        this.$i18n.locale = 'ug'
      } else {
        this.$i18n.locale = 'zh'
      }
    },
    // 点击类目
    onType (id) {
      this.$router.push({ name: 'documentaryHome' })
    },
  },
}
</script>

<style scoped>
.documentaryFilmPage {
  background-size: cover;
  /* height: 100vh; */
  background-repeat: repeat;
}
.opacity-page {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
