<template>
  <div class="DocumentaryType" :style="`background-image: url(${staticUrl}index_bg.png)`">

    <div class="DocumentaryTypeOpacity full-height">

      <!-- search -->
      <div class="row justify-center e-pt-30">
        <div class="height-40 row items-center e-bg-grey-3 border-radiusL-10 e-px-10"></div>
        <input type="text"
          class="e-bg-grey-3 border-radius-none height-40 rtl outline-none font-16 text-white izbol width-250"
          placeholder="IZIMEKQI BOLGAN ESIMNI KIRGUZUG" v-model="searchKey">
        <div class="height-40 row items-center e-bg-grey-3 border-radiusR-10 e-px-10" style="padding: 7px;"
          @click="onSearch">
          <div style="background-color: #9a9a9a; width: 1px;" class="full-height"></div>
          <img :src="staticUrl + 'search-icon.png'" alt="" class="width-25 e-ml-5" />
        </div>
      </div>

      <!-- 公司 -->
      <div class="e-px-30 e-mt-20 gongsi-box">
        <div v-for="item of firmList" class="e-bg-grey-4 row justify-center items-center height-55 border-radius-50p"
          @click="onFirm(item.id)">
          <img :src="staticUrl+item.imageUrl" alt="" style="width: 30px;" />
        </div>
      </div>

      <!-- 类型 -->
      <div class="typeBox e-px-30 e-mt-10">
        <div class="relative-position height-25" v-for="item in typeList" @click="onType(item.id)">
          <img class="full-width absolute" :src="staticUrl + item.imageUrl" alt="" />
          <span class="text-white izbol absolute right-0 line-height-30 font-10">{{item.name}}</span>
        </div>
      </div>

      <!-- 片 -->
      <div class="item-box e-px-30 e-mt-20 e-pb-50">
        <div class="" v-for="(item, index) in movieList" @click="onMovie(item.id)">
          <img class="full-width" :src="staticUrl+item.imageUrl" alt="" />
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'DocumentaryType',
  components: {},
  data () {
    return {
      searchKey: '',
      staticUrl: 'https://bayan.adaxtv.com/static/image/',
      movieList: [
        { id: 1, imageUrl: 'demo-1.png' },
        { id: 2, imageUrl: 'demo-2.png' },
        { id: 3, imageUrl: 'demo-3.png' },
        { id: 4, imageUrl: 'demo-4.png' },
        { id: 5, imageUrl: 'demo-5.png' },
        { id: 6, imageUrl: 'demo-6.png' },
        { id: 7, imageUrl: 'demo-1.png' },
        { id: 8, imageUrl: 'demo-2.png' },
        { id: 9, imageUrl: 'demo-3.png' },
        { id: 10, imageUrl: 'demo-4.png' },
        { id: 11, imageUrl: 'demo-5.png' },
        { id: 12, imageUrl: 'demo-6.png' },
        { id: 13, imageUrl: 'demo-1.png' },
        { id: 14, imageUrl: 'demo-2.png' },
        { id: 15, imageUrl: 'demo-3.png' },
      ],
      typeList: [
        { id: 1, name: 'شەخىس', imageUrl: 'xahis.png' },
        { id: 2, name: 'تېخنىكا', imageUrl: 'tehnika.png' },
        { id: 3, name: 'تەبىئەت', imageUrl: 'tabiat.png' },
        { id: 4, name: 'تارىخ', imageUrl: 'tarih.png'  },
        { id: 5, name: 'تەنتەربىيە', imageUrl: 'tantarbiya.png' },
        { id: 6, name: 'مەدىنىيەت', imageUrl: 'madiniyat.png' },
        { id: 7, name: 'كەسىپ', imageUrl: 'kasip.png' },
        { id: 8, name: 'ساياھەت', imageUrl: 'sayahat.png' },
        { id: 9, name: 'سۆھبەت', imageUrl: 'sohpat.png' },
        { id: 10, name: 'تۇرمۇش', imageUrl: 'turmux.png' }
      ],
      firmList: [
        { id: 1, imageUrl: 'firm-1.png'  },
        { id: 2, imageUrl: 'firm-2.png'  },
        { id: 3, imageUrl: 'firm-3.png'  },
        { id: 4, imageUrl: 'firm-4.png'  },
        { id: 5, imageUrl: 'firm-5.png'  },
        { id: 6, imageUrl: 'firm-6.png'  }
      ],
    }
  },
  methods: {
    // 点击搜索
    onSearch () {
      console.log("searchKey: ", this.searchKey);
    },

    // 点击类型
    onType(id) {
      console.log("类型 id: ", id);
    },

    // 点击电影
    onMovie(id) {
      this.$router.push({ name: 'playPage' })
    },

    // 点击公司
    onFirm (id) {
      console.log("公司 id: ", id);
    }
  },
}
</script>

<style scoped>
.DocumentaryType {
  background-size: contain;
}

.DocumentaryTypeOpacity {
  background-color: rgba(48, 56, 66, 0.5);
}

.typeBox {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.item-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  padding-bottom: 50px;
}

.gongsi-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 8px;
}
</style>