<template>
  <div class="DocumentaryHome height-fit" :style="`background-image: url(${staticUrl}${bgImageName})`">

    <div class="DocumentaryHomeOpacity full-height">
      <!-- tab -->
      <div class="tab e-pt-30 e-px-30 row justify-between">
        <div class="border-radius-50p e-bg-grey-4 row items-center justify-center height-55 width-55" @click="onMine">
          <img class="height-30" :src="staticUrl+'mine.png'" alt="" />
        </div>
        <div class="column items-center izbol text-white">
          <div>BAYAN</div>
          <div>FILM AND TELEVISION</div>
        </div>
        <div class="border-radius-50p e-bg-grey-4 row items-center justify-center height-55 width-55"
          @click="onDocumentaryType">
          <img class="height-20" :src="staticUrl+'type.png'" alt="" />
        </div>
      </div>

      <!-- search -->
      <div class="row justify-center e-mt-20">
        <div class="height-40 row items-center e-bg-grey-3 border-radiusL-10 e-px-10"></div>
        <input type="text"
          class="e-bg-grey-3 border-radius-none height-40 rtl outline-none font-16 text-white izbol width-250"
          placeholder="IZIMEKQI BOLGAN ESIMNI KIRGUZUG" v-model="searchKey">
        <div class="height-40 row items-center e-bg-grey-3 border-radiusR-10 e-px-10" style="padding: 7px;"
          @click="onSearch">
          <div style="background-color: #9a9a9a; width: 1px;" class="full-height"></div>
          <img :src="staticUrl + 'search-icon.png'" alt="" class="width-25 e-ml-5" />
        </div>
      </div>

      <!-- 公司 -->
      <div class="e-px-30 e-mt-20 gongsi-box">
        <div v-for="item of firmList" class="e-bg-grey-4 row justify-center items-center height-55 border-radius-50p"
          @click="onFirm(item.id)">
          <img :src="staticUrl+item.imageUrl" alt="" style="width: 30px;" />
        </div>
      </div>

      <!-- 轮播图 -->
      <div class="e-px-30 e-mt-20 overflow">
        <swiper @slideChange="onSlideChange" :slidesPerView="3" :spaceBetween="30" :loop="true" :freeMode="true"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }" :modules="modules" class="mySwiper">
          <swiper-slide v-for="(item, index) in movieList" @click="onMovie(item.id)">
            <div class="border-radius-8 width-110 e-mr-10" style="background-color: rgba(56, 53, 54, 0.7);">
              <img class="full-width" :src="staticUrl + item.imageUrl" alt="" />
              <div class="rtl e-px-10 e-pb-10 main-text-color font-8 izbol row justify-between"
                v-if="swiperIndex == index">
                <div class="">
                  <div>
                    <span>نامى: </span>
                    <span class="text-white">JOKER</span>
                  </div>
                  <div>
                    <span>ۋاقتى:</span>
                    <span>1سائەت 50 مىنۇت</span>
                  </div>
                </div>
                <div class="relative-position">
                  <img style="width: 20px;" :src="staticUrl+'qambar.png'" alt="" />
                  <div class="absolute" style="top: 6px; left: 6px;">{{ item.num }}</div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <!--  -->
      <div class="row justify-center e-px-30 e-mt-30">
        <img :src="staticUrl+'swper-b.png'" alt="" style="height: 13px;" />
      </div>

      <!-- 底部提示 -->
      <div class="e-px-30 e-mt-50 items-center row justify-center">
        <div class="e-bg-light-green-1" style="height: 1px; width: 120px;"></div>
        <div class="izbol main-text-color e-mx-20">تىزىملىك</div>
        <div class="e-bg-light-green-1" style="height: 1px; width: 120px;"></div>
      </div>

    </div>

  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
export default {
  name: 'DocumentaryHome',
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      searchKey: '',
      staticUrl: 'https://bayan.adaxtv.com/static/image/',
      movieList: [
        { id: 1, imageUrl: 'demo-1.png', num: '8.3' },
        { id: 2, imageUrl: 'demo-2.png', num: '7.5' },
        { id: 3, imageUrl: 'demo-3.png', num: '7.9' },
        { id: 4, imageUrl: 'demo-4.png', num: '9.5' },
        { id: 5, imageUrl: 'demo-5.png', num: '6.7' },
        { id: 6, imageUrl: 'demo-6.png', num: '8.6' },
        { id: 7, imageUrl: 'demo-1.png', num: '8.1' },
        { id: 8, imageUrl: 'demo-2.png', num: '7.6' },
        { id: 9, imageUrl: 'demo-3.png', num: '7.7' },
        { id: 10, imageUrl: 'demo-4.png', num: '8.8' },
        { id: 11, imageUrl: 'demo-5.png', num: '5.9' },
        { id: 12, imageUrl: 'demo-6.png', num: '9.0' },
      ],
      firmList: [
        { id: 1, imageUrl: 'firm-1.png'  },
        { id: 2, imageUrl: 'firm-2.png'  },
        { id: 3, imageUrl: 'firm-3.png'  },
        { id: 4, imageUrl: 'firm-4.png'  },
        { id: 5, imageUrl: 'firm-5.png'  },
        { id: 6, imageUrl: 'firm-6.png'  }
      ],
      swiperIndex: 0,
      modules: [Autoplay, Pagination, Navigation],
      isEndTriggered: false,
      bgImageName: 'index_bg.png',
    }
  },
  methods: {
    // 点击搜索
    onSearch () {
      console.log("searchKey: ", this.searchKey);
    },

    // 轮播图切换
    onSlideChange (swiper) {
      const activeIndex = swiper.realIndex;

      if (activeIndex == this.movieList.length - 1) {
        this.swiperIndex = 0;
      } else {
        this.swiperIndex = activeIndex + 1;
      }

      for (let item of this.movieList) {
        if (item.id == activeIndex+2) {
          this.bgImageName = item.imageUrl;
        }
      }
 
    },

    // 点击类型
    onType(id) {
      console.log("类型 id: ", id);
    },

    // 点击电影
    onMovie(id) {
      this.$router.push({ name: 'playPage' })
    },

    // 点击公司
    onFirm (id) {
      console.log("公司 id: ", id);
    },

    // 点击往 type 前页
    onDocumentaryType () {
      this.$router.push({ name: 'documentaryType' })
    },

    // 点击往 mine 前页
    onMine () {
      this.$router.push({ name: 'mine' })
    }
  },
}
</script>

<style scoped>
.DocumentaryHome {
  background-size: cover;
}

.DocumentaryHomeOpacity {
  background-color: rgba(48, 56, 66, 0.9);
}

.typeBox {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.item-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  padding-bottom: 50px;
}

.gongsi-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 8px;
}

</style>