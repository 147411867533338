export default {
  isoName: "ug-cn",
  nativeName: "hanzi",
  rtl: false,
  common: {
    home: '首页',
    finish: '完成',
    haveBought: '已购买',
    preview: '预览',
    share: '分享',
    contactUs: '联系我们',
    aboutUs: '关于我们',
    purchase: '购买',
    subscribeToUnlimitedViewing: '订会员无限观看',
    documentaryFilm: '纪录片类目',
    people: '人物',
    science: '科学',
    nature: '自然',
    history: '历史',
    sports: '体育',
    culture: '文化',
    specialty: '专业',
    tour: '旅游',
    interview: '旅游',
    life: '生活',
    originalFilm: '原片电影',
    shareWithFriends: '与朋友分享',
    collect: '收藏',
    complaintAndAdvice: '投诉建议',
    relatedFilm: '相关片子',
    subscriptionMember: '订购会员',
    monthMembership_1: '一个月会员',
    monthMembership_3: '三个月会员',
    monthMembership_6: '六个月会员',
    monthMembership_12: '年会员',
  }
}